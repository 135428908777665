import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from "../../components/seo";
import PGSideBar from '../../components/pg-side-bar';

import '../../styles/cus.css';

const PatentGuide_AIA = () => {
    const seo = {
        metaDesc: 'Understand American Invents Act (AIA) - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="Understand American Invents Act (AIA) - Inventiv.org" canonical='/patent-guide' seo={seo} />
            <div class="sub-banner main-banner">
                <div class="container">
                    <div class="breadcrumb-area" style={{ top: 30 + '%' }}>
                        <h1 className='fw-special-title'>&nbsp;</h1>
                        <h1 className='title-banner'>Understand American Invents Act (AIA)</h1>
                    </div>
                </div>
            </div>

            <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
                <div class="container ">
                    <div className='row'>
                        <div className='col-md-8 col-sm-12'>
                            <figure className='text-center'>
                                <img width="300" height="300"
                                    src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300,h_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png"
                                    data-src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300,h_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png"
                                    class="vc_single_image-img attachment-medium lazyloaded" alt="" decoding="async"
                                    data-srcset="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png 300w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_150/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-150x150.png 150w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_100/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-100x100.png 100w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_512/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1.png 512w"
                                    data-sizes="(max-width: 300px) 100vw, 300px" data-pagespeed-url-hash="2358126750" sizes="(max-width: 300px) 100vw, 300px"
                                    srcset="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png 300w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_150/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-150x150.png 150w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_100/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-100x100.png 100w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_512/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1.png 512w" />
                            </figure>
                            <div class="wpb_wrapper">
                                <h2>Understand American Invents Act (AIA)</h2>
                                <p><span style={{ fontWeight: 400 }}>The American Invents Act has dramatically changed how the patent system
                                    operates.&nbsp; Here’s a brief overview of some of the changes it entails and what it means for
                                    you.</span></p>
                                <p>&nbsp;</p>
                                <h3><b>A. What does it say?</b></h3>
                                <p>&nbsp;</p>
                                <ul>
                                    <li><span style={{ fontWeight: 400 }}> 102. Conditions for patentability; novelty</span></li>
                                </ul>
                                <p><span style={{ fontWeight: 400 }}>(a) NOVELTY; PRIOR ART.—A person shall be entitled to a patent unless—</span>
                                </p>
                                <p><span style={{ fontWeight: 400 }}>(1) the claimed invention was patented, described in a printed publication,
                                    or in public use, on sale, or otherwise available to the public before the effective filing date
                                    of the claimed invention; or</span></p>
                                <p><span style={{ fontWeight: 400 }}>(2) the claimed invention was described in a patent issued under section 151,
                                    or in an application for patent published or deemed published under section 122(b), in which the
                                    patent or application, as the case may be, names another inventor and was effectively filed
                                    before the effective filing date of the claimed invention.</span></p>
                                <p><span style={{ fontWeight: 400 }}>(b) EXCEPTIONS.—</span></p>
                                <p><span style={{ fontWeight: 400 }}>(1) DISCLOSURES MADE 1 YEAR OR LESS BEFORE THE EFFECTIVE FILING DATE OF THE
                                    CLAIMED INVENTION.—A disclosure made 1 year or less before the effective filing date of a
                                    claimed invention shall not be prior art to the claimed invention under subsection (a)(1)
                                    if—</span></p>
                                <p><span style={{ fontWeight: 400 }}>(A) the disclosure was made by the inventor or joint inventor or by another
                                    who obtained the subject matter disclosed directly or indirectly from the inventor or a joint
                                    inventor; or</span></p>
                                <p><span style={{ fontWeight: 400 }}>(B) the subject matter disclosed had, before such disclosure, been publicly
                                    disclosed by the inventor or a joint inventor or another who obtained the subject matter
                                    disclosed directly or indirectly from the inventor or a joint inventor.</span></p>
                                <p><span style={{ fontWeight: 400 }}>(2) DISCLOSURES APPEARING IN APPLICATIONS AND PATENTS.— A disclosure shall
                                    not be prior art to a claimed invention under subsection (a)(2) if—</span></p>
                                <p><span style={{ fontWeight: 400 }}>(A) the subject matter disclosed was obtained directly or indirectly from the
                                    inventor or a joint inventor;</span></p>
                                <p><span style={{ fontWeight: 400 }}>(B) the subject matter disclosed had, before such subject matter was
                                    effectively filed under subsection (a)(2), been publicly disclosed by the inventor or a joint
                                    inventor or another who obtained the subject matter disclosed directly or indirectly from the
                                    inventor or a joint inventor; or</span></p>
                                <p><span style={{ fontWeight: 400 }}>(C) the subject matter disclosed and the claimed invention, not later than
                                    the effective filing date of the claimed invention, were owned by the same person or subject to
                                    an obligation of assignment to the same person.</span></p>
                                <p>&nbsp;</p>
                                <h3><b>B. How will AIA change the patent application process?</b></h3>
                                <p>&nbsp;</p>
                                <ol>
                                    <li><b><i> first-to-file system</i></b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>AIA favors an inventor who is first to disclose versus an inventor who is
                                    first to file.&nbsp; Under the first-to-file system, the first applicant to file is entitled to
                                    a patent for the invention unless the invention was derived from other people.&nbsp; Rights to a
                                    patent are determined by inventors who filed first, not who first conceived of the invention. To
                                    preserve and safeguard your patent rights, you should, thus, consider filing a high quality
                                    provisional patent application as soon as you can.</span></p>
                                <ol start="2">
                                    <li><b><i> best mode disclosure</i></b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>Under AIA, failure to disclose the best mode of practicing the invention will
                                    not invalidate a patent.&nbsp; If you are only interested in U.S. patent rights, then you should
                                    disclose the invention as soon as possible to make the disclosure prior art to all potential
                                    filers. Remember with this, you still have the option of filing patent applications within the
                                    one-year grace period.</span></p>
                                <p><span style={{ fontWeight: 400 }}>However, in certain international countries, such disclosure can act as prior
                                    art.&nbsp; This may bar your subsequent patent application and destroys your patent
                                    rights.&nbsp; Thus, if you want international protection, the best way to gain that is to file
                                    the disclosure as a provisional application or a non-provisional application if you’ve already
                                    completed your claims.&nbsp; After filing that, then you can make the disclosure.&nbsp; Next,
                                    within one year, you can file your international applications.</span></p>
                                <p><span style={{ fontWeight: 400 }}>If you’re thinking about using non-disclosure and confidentiality agreements
                                    to show derivation by the recipient of the information, note that it is useless against third
                                    parties. Another downside is that the derivation process can be expensive. Hence, the cheapest
                                    alternative is to file provisional or utility patent applications prior to discussing the
                                    invention with anyone.</span></p>
                                <p><span style={{ fontWeight: 400 }}>Some people assume that with an early disclosure approach, it takes
                                    significant time to prepare a patent application. However, with ProvisionalBuilder™, you can
                                    prepare high quality applications quickly and easily.&nbsp; And that allows a third
                                    approach—file a provisional patent application and, at the same time or slightly thereafter,
                                    publicly disclose the invention. With this approach, you get the best of both worlds; you can
                                    prepare a high-quality, provisional patent application inexpensively that’s ready for conversion
                                    into a utility patent, and you can bar others from patenting the same invention.</span></p>
                                <h3><b>C. How does AIA change when I apply for a patent?&nbsp;</b></h3>
                                <p><span style={{ fontWeight: 400 }}>Under prior law, use or sale outside of the United States did not necessarily
                                    invalidate a patent. However, under AIA, if the invention is otherwise made available to the
                                    public or on sale before the effective filing date of a patent application anywhere in the world
                                    that can be used by the USPTO to reject an application. However, such disclosure isn’t
                                    applicable if it was made less than one year before filing the patent application, and the
                                    disclosure was made by the inventor or someone who derived the inventive subject matter from the
                                    inventor.</span></p>
                                <p><span style={{ fontWeight: 400 }}>Thus, if a second patent application filer publicly disclosed the subject
                                    matter before the first inventor filed a patent application, then the second filer can use the
                                    earlier public disclosure to overcome a USPTO rejection.&nbsp; He/she can use his/her public
                                    disclosure as prior art against the first inventor’s patent application.</span></p>
                                <p><span style={{ fontWeight: 400 }}>AIA promotes pre-filing disclosure because the grace period is only available
                                    for those who publicly disclose the invention before filing. When an inventor makes the first
                                    public disclosure of an invention, public disclosures and patent applications filed by others
                                    for the same invention are not considered prior art for one year after that public disclosure.
                                    Therefore, by making the first public disclosure of the invention, the first-to-disclose
                                    inventor gets:</span></p>
                                <ul>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>a one year grace period in which you can
                                        file your application without the USPTO using your own public disclosure against you,
                                        and&nbsp;</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>the ability to block the benefits of
                                        others who are the first-to-file because your earlier public disclosure bars later
                                        application even if others were the first-to-file.</span><b></b></li>
                                </ul>
                                <h3>D. Is AIA good for bad for me?</h3>
                                <p><span style={{ fontWeight: 400 }}>Initial findings about AIA’s effect on individual inventors aren’t so
                                    great.&nbsp; David Abrams and Polk Wagner, both law professors at the University of
                                    Pennsylvania, researched whether the change from a first-to-invent to a first-to-file regime is
                                    likely to disadvantage individual inventors in their paper “Priority Rules: An Empirical
                                    Exploration of First- to-Invent Versus First-to-File.” They found a significant drop in the
                                    number of patents granted to small inventors in Canada coincided with the implementation of
                                    first-to-file. The concern is that well financed and IP savvy corporations will have an easier
                                    time than an individual when quickly drafting and filing a patent application.</span></p>
                                <p>&nbsp;</p>
                            </div>
                            <div className='mt-5'>
                                <p>
                                <a href='/inventor-resources' className='btn btn-warning rounded-pill px-5'>Download Provisional Patent Assistant Now!</a>
                                </p>
                            </div>
                        </div>

                        <div className='col-md-4 col-sm-12'>
                            <PGSideBar></PGSideBar>
                        </div>
                    </div>

                </div>
            </section>

        </Layout>
    )
};

export default PatentGuide_AIA;